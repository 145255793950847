/* Regular */
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Semibold */
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;  /* Adjust according to your font's specification */
  font-style: normal;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
body, html {
  font-family: 'Raleway', sans-serif; 
}
